import AdditionalInfo from "../../components/AdditionalInfo";
import Card from "../../components/Card";
import { Table } from "../../components/Compare/Table";
import CompareHeader from "../../components/CompareHeader";
import Page from "../../components/Page";
import { ProductType, FeatureType } from "../../data/business-security";

export const name = "Security для бизнеса";
export const path = "/security-for-business";
export const title = `Kaspersky ${name}`;

export const element = () => (
  <Page>
    <CompareHeader
      $title={
        <>
          Kaspersky <strong>{name}</strong>
        </>
      }
    >
      <p>
        Защита конечных точек – фундамент системы информационной безопасности.
        Kaspersky Security для бизнеса – гибкая линейка продуктов для защиты
        рабочих мест с широкими возможностями для управления безопасностью
        компаний любого масштаба. Выберите уровень с подходящим вашей компании
        набором инструментов защиты.
      </p>
    </CompareHeader>

    <Table
      products={Object.values(ProductType)}
      features={Object.values(FeatureType)}
    />

    <AdditionalInfo>
      <Card icon="browse" title="Обзор">
        <p>Изучите информацию об интересующем решении</p>
        <ul>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://content.kaspersky-labs.com/se/media/ru/business-security/Kaspersky_Security_for_Business_datasheet.pdf"
            >
              Общий обзор
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://content.kaspersky-labs.com/se/media/ru/business-security/Kaspersky_Security_for_Business_Featurelist.pdf"
            >
              Подробный обзор
            </a>
          </li>
        </ul>
      </Card>
      <Card icon="compare" title="Сравнение">
        <p>Посмотрите сравнение и выберите оптимальное решение</p>
        <ul>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://content.kaspersky-labs.com/se/media/ru/business-security/kaspersky-security-for-business-level-comparison.pdf"
            >
              Общий обзор
            </a>
          </li>
        </ul>
      </Card>
      <Card icon="goto" title="Страница решения">
        <p>Прейдите на страницу решений и получие больше информации</p>
        <ul>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.kaspersky.ru/small-to-medium-business-security/endpoint-protection"
            >
              Перейти
            </a>
          </li>
        </ul>
      </Card>
    </AdditionalInfo>
  </Page>
);
