import { Table } from "../../components/Compare/Table";
import Page from "../../components/Page";
import { ProductType as ESC_ProductType } from "../../data/esc";
import { ProductType as Symphony_ProductType } from "../../data/symphony";
import {
  ProductType as BS_ProductType,
  FeatureType as BS_FeatureType,
} from "../../data/business-security";
import CompareHeader from "../../components/CompareHeader";
import Card from "../../components/Card";
import AdditionalInfo from "../../components/AdditionalInfo";

export const path = "/all-solutions";
const shortTitle = "Все решения";
const description = "для защиты конечных точек";
export const title = "Все решения для защиты конечных точек";

export const element = () => (
  <Page>
    <CompareHeader
      $title={
        <>
          {shortTitle} <strong>{description}</strong>
        </>
      }
    >
      <p>
        Технологии «
        <a href="kaspersky.ru" target="_blank" rel="noreferrer">
          Лаборатории Касперского
        </a>
        » по защите конечных точек регулярно получают признание экспертов и
        пользователей в ходе независимых международных тестирований. Выберите
        подходящее вам решение из разных линеек «
        <a href="kaspersky.ru" target="_blank" rel="noreferrer">
          Лаборатории Касперского
        </a>
        », исходя из потребностей, ресурсов и планов по развитию системы
        информационной безопасности.
      </p>
    </CompareHeader>
    <Table
      products={[
        ...Object.values(ESC_ProductType),
        ...Object.values(BS_ProductType),
        Symphony_ProductType.SymphonySecurity,
      ]}
      features={Object.values(BS_FeatureType)}
    />

    <AdditionalInfo>
      <Card icon="browse" title="Обзор">
        <p>Изучите информацию об интересующем решении</p>
        <ul>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://content.kaspersky-labs.com/se/media/ru/business-security/kaspersky-security-for-enterprise-catalogue.pdf"
            >
              Каталог решений
            </a>
          </li>
        </ul>
      </Card>
    </AdditionalInfo>
  </Page>
);
