import styled from "styled-components";
import { Logo } from "./Logo";

const Root = styled("header")<Pick<HeaderProps, "image">>(({ image }) => ({
  "::before": {
    content: "''",
    backgroundPosition: "top, center",
    backgroundSize: "cover, cover",
    backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0) 72%, #FFFFFF), url("${image}")`,
    position: "absolute",
    display: "block",
    height: "100%",
    width: "100vw",
    minWidth: 1280,
    left: "50%",
    top: 0,
    transform: "translateX(-50%)",
    zIndex: -1,
  },
  minHeight: 715,
  position: "relative",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

const Title = styled("h1")({
  fontSize: 50,
  lineHeight: 58.6 / 50,
  fontWeight: 600,
  maxWidth: 860,
});
const Lead = styled("p")({
  padding: 0,
  fontSize: 20,
  lineHeight: 23.44 / 20,
  fontWeight: 400,
  maxWidth: 560,
});

interface HeaderProps {
  image: string;
}
export const Header = ({ image }: HeaderProps) => (
  <Root image={image}>
    <Logo />
    <Title>Выберите защиту, которая подойдет именно вам</Title>
    <Lead>
      Сравните функциональные возможности продуктов &laquo;Лаборатории
      Касперского&raquo; и выберите подходящее решение для ваших задач
      и&nbsp;потребностей
    </Lead>
  </Root>
);
