import { createBrowserRouter } from "react-router-dom";
import * as securityForBusiness from "./security-for-business";
import * as endpointSecurityCloud from "./endpoint-security-cloud";
import * as symphony from "./symphony";
import * as securityHybrid from "./security-hybrid";
import * as allSolutions from "./all-solutions";

const router = createBrowserRouter([
  {
    path: securityForBusiness.path,
    element: <securityForBusiness.element />,
  },
  {
    path: endpointSecurityCloud.path,
    element: <endpointSecurityCloud.element />,
  },
  {
    path: symphony.path,
    element: <symphony.element />,
  },
  {
    path: securityHybrid.path,
    element: <securityHybrid.element />,
  },
  {
    path: allSolutions.path,
    element: <allSolutions.element />,
  },
  {
    path: "*",
    element: <securityForBusiness.element />,
  },
]);

export default router;
