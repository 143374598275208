import * as securityForBusiness from "./security-for-business";
import * as endpointSecurityCloud from "./endpoint-security-cloud";
import * as symphony from "./symphony";
import * as securityHybrid from "./security-hybrid";
import * as allSolutions from "./all-solutions";

const routes = [
  securityForBusiness,
  endpointSecurityCloud,
  symphony,
  securityHybrid,
  allSolutions,
];
export default routes;
