import { FeatureValue } from "../../products/features";
import { ReactComponent as CheckSvg } from "../../icons/check.svg";
import styled from "styled-components";

interface ValueProps {
  value?: FeatureValue;
}

const noramlize = (value: FeatureValue) => {
  switch (typeof value) {
    case "boolean":
      return { value };
    case "string":
      return { text: value };
    default:
      return value;
  }
};

const Root = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

const Icon = styled(CheckSvg)({});

const Text = styled("span")(({ theme }) => ({
  color: theme.colors.kaspersky,
  display: "block",
  fontWeight: 400,
  fontSize: 12,
  lineHeight: 14 / 12,
  // maxWidth: 80,
  textAlign: "center",
  "&:not(:first-child)": {
    marginTop: 10,
  },
}));

export const Value = (props: ValueProps) => {
  if (!props.value) {
    return null;
  }

  const { value, text } = noramlize(props.value);

  return (
    <Root>
      {value && <Icon />}
      {text && <Text>{text}</Text>}
    </Root>
  );
};
