import { ReactNode } from "react";
import styled from "styled-components";

interface AdditionalInfoProps {
  children: ReactNode;
}

const Root = styled("section")({
  marginTop: 150,
  marginBottom: 150,
});

const Content = styled("div")({
  display: "flex",
  gap: 40,

  "& > *": {
    width: "30%",
  },
});

const Title = styled("h3")(({ theme }) => ({
  fontWeight: 600,
  fontSize: 40,
  lineHeight: 46.88 / 40,
  strong: {
    fontWeight: "inherit",
    color: theme.colors.kaspersky,
  },

  marginTop: 0,
  marginBottom: 50,
}));

const AdditionalInfo = ({ children }: AdditionalInfoProps) => (
  <Root>
    <Title>
      Дополнительная <strong>информация</strong>
    </Title>
    <Content>{children}</Content>
  </Root>
);

export default AdditionalInfo;
