import AdditionalInfo from "../../components/AdditionalInfo";
import Card from "../../components/Card";
import { Table } from "../../components/Compare/Table";
import CompareHeader from "../../components/CompareHeader";
import Page from "../../components/Page";
import { ProductType, FeatureType } from "../../data/vhc";

export const name = "Security для виртуальных и облачных сред";
export const path = "/security-hybrid";
export const title = `Kaspersky ${name}`;

export const element = () => (
  <Page>
    <CompareHeader
      $title={
        <>
          Kaspersky <strong>{name}</strong>
        </>
      }
    >
      <p>
        Многоуровневая защита, подкрепленная алгоритмами машинного обучения и
        аналитикой угроз в режиме реального времени, противодействует широкому
        спектру киберугроз, в том числе фишингу, шифровальщикам и другому
        вредоносному ПО.
      </p>
    </CompareHeader>

    <Table
      products={Object.values(ProductType)}
      features={Object.values(FeatureType)}
    />

    <AdditionalInfo>
      <Card icon="browse" title="Обзор">
        <p>Изучите информацию об интересующем решении</p>
        <ul>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://content.kaspersky-labs.com/se/media/ru/business-security/Сloud_protection_and_high_performance_for_hybrid_infrastructure.pdf"
            >
              Общий обзор
            </a>
          </li>
        </ul>
      </Card>
      <Card icon="compare" title="Сравнение">
        <p>Посмотрите сравнение и выберите оптимальное решение</p>
        <ul>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://content.kaspersky-labs.com/se/media/ru/business-security/Overview_of_possibilities_Kaspersky_Hybrid_Cloud_Security(Standard_and_Enterprise).pdf"
            >
              Общий обзор
            </a>
          </li>
        </ul>
      </Card>
      <Card icon="goto" title="Страница решения">
        <p>Прейдите на страницу решений и получие больше информации</p>
        <ul>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.kaspersky.ru/small-to-medium-business-security/cloud-security"
            >
              Перейти
            </a>
          </li>
        </ul>
      </Card>
    </AdditionalInfo>
  </Page>
);
