import * as icons from "../icons";
import styled from "styled-components";
import { ReactNode } from "react";

interface CardProps {
  title: string;
  icon: keyof typeof icons;
  children: ReactNode;
}

const Root = styled("div")({
  background: "#F5F5F5",
  borderRadius: 20,

  display: "flex",
  gap: 25,

  padding: "25px 37.5px",
});

// const Decor = styled("div")({});
const Content = styled("div")({});

const Title = styled("h4")({
  marginTop: 0,
  marginBottom: 10,

  fontWeight: 500,
  fontSize: 25,
  lineHeight: 29.3 / 25,
});
const Text = styled("div")(({ theme }) => ({
  p: {
    color: theme.colors.darkGrey,
    margin: 0,

    "&:not(:last-child)": {
      marginBottom: 20,
    },
  },
  ul: {
    listStyleType: "none",
    margin: 0,
    padding: 0,
    display: "flex",
    gap: 40,

    fontWeight: 600,
    fontSize: 16,
    lineHeight: 18.75 / 16,
  },
}));

const Card = ({ title, icon, children }: CardProps) => {
  const Icon = icons[icon];

  return (
    <Root>
      {Icon && <Icon />}
      <Content>
        <Title>{title}</Title>
        <Text>{children}</Text>
      </Content>
    </Root>
  );
};

export default Card;
