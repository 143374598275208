import styled from "styled-components";
import { FeatureGroupBase } from "../../../data/features";
import { Caption } from "./Caption";

const Root = styled(Caption)(({ theme }) => ({
  color: theme.colors.kaspersky,
  cursor: "pointer",

  svg: {
    path: {
      fill: "currentColor",
    },
  },

  "@media (hover: hover)": {
    "&:hover": {
      color: theme.colors.kasperskyTeal,
    },
  },
}));

const Toggle = styled("span")({
  width: 12,
  minWidth: 12,
  height: 12,
  position: "relative",
  "&::before, &::after": {
    content: "''",
    height: 2,
    backgroundColor: "currentColor",
    position: "absolute",
    top: 5,
    left: 0,
    width: 12,
    transition: "transform 0.25s",
  },
  "&[data-state=false]::after": {
    transform: "rotate(90deg)",
  },
});

const Title = styled("h2")((props) => ({
  margin: 0,
  fontWeight: 400,
  fontSize: 20,
  lineHeight: 23.44 / 20,
  textAlign: "left",
}));

const Icon = styled("div")({});

export interface GroupProps extends FeatureGroupBase {
  state?: boolean;
}

export const Group = ({ title, icon: IconSvg, state }: GroupProps) => {
  return (
    <Root>
      {IconSvg && (
        <Icon>
          <IconSvg />
        </Icon>
      )}
      <Title>{title}</Title>
      <Toggle data-state={state} />
    </Root>
  );
};
