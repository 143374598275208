import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";
import routes from "../routes";
import { Page } from "../routes/types";

// const menu = [
//   "Kaspersky Security для бизнеса",
//   "Kaspersky Endpoint Security Cloud",
//   "Kaspersky Symphony",
//   "Kaspersky Security для виртуальных и облачных сред",
//   ["Все решения", "для защиты конечных точек"],
// ];

const Root = styled("nav")(({ theme: { maxWidth, colors } }) => ({
  borderRadius: 5,
  background: colors.kaspersky,
  maxWidth,
  marginLeft: "auto",
  marginRight: "auto",
}));

const List = styled("ul")({
  color: "#fff",
  appearance: "none",
  margin: 0,
  padding: 0,
  listStyleType: "none",
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  fontWeight: 400,
  fontSize: 16,
  lineHeight: 18.75 / 16,
});

const Li = styled("li")({
  boxSizing: "border-box",
  //   width: "19%",
  position: "relative",
  padding: 24,

  ":has(a[href='/security-hybrid'])": {
    width: "22%",
  },
  ":has(a[href='/all-solutions'])": {
    width: "22%",
  },
});

const Link = styled(RouterLink)({
  display: "block",
  color: "inherit",
  textDecoration: "none",
  "::before": {
    content: "''",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  "&[href='/security-for-business']": {
    maxWidth: 160,
  },
  "&[href='/endpoint-security-cloud']": {
    maxWidth: 190,
  },
  "&[href='/symphony']": {
    maxWidth: 90,
  },
});
const Description = styled("span")({
  display: "block",
  fontSize: "x-small",
});

export default function Navigation() {
  return (
    <Root>
      <List>
        {routes.map(({ title, description, path }: Page, ix) => (
          <Li key={ix}>
            <Link to={path} {...{ title }}>
              {title}
            </Link>
            {description && <Description>{description}</Description>}
          </Li>
        ))}
      </List>
    </Root>
  );
}
