import AdditionalInfo from "../../components/AdditionalInfo";
import Card from "../../components/Card";
import { Table } from "../../components/Compare/Table";
import CompareHeader from "../../components/CompareHeader";
import Page from "../../components/Page";
import { ProductType, FeatureType } from "../../data/esc";

export const name = "Endpoint Security Cloud";
export const path = "/endpoint-security-cloud";
export const title = `Kaspersky ${name}`;

export const element = () => (
  <Page>
    <CompareHeader
      $title={
        <>
          Kaspersky <strong>{name}</strong>
        </>
      }
    >
      <p>
        Управление защитой компьютеров, мобильных устройств, серверов и
        приложений из любой точки мира через облачную консоль. Выберите уровень
        с подходящим вашей компании набором защитных инструментов.
      </p>
    </CompareHeader>

    <Table
      products={Object.values(ProductType)}
      features={Object.values(FeatureType)}
    />

    <AdditionalInfo>
      <Card icon="browse" title="Обзор">
        <p>Изучите информацию об интересующем решении</p>
        <ul>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href={require("./esc-overview.pdf")}
            >
              Общий обзор
            </a>
          </li>
        </ul>
      </Card>
      <Card icon="compare" title="Сравнение">
        <p>Посмотрите сравнение и выберите оптимальное решение</p>
        <ul>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href={require("./esc-compare.pdf")}
            >
              Общий обзор
            </a>
          </li>
        </ul>
      </Card>
      <Card icon="goto" title="Страница решения">
        <p>Прейдите на страницу решений и получие больше информации</p>
        <ul>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.kaspersky.ru/small-to-medium-business-security/cloud"
            >
              Перейти
            </a>
          </li>
        </ul>
      </Card>
    </AdditionalInfo>
  </Page>
);
