import styled from "styled-components";

export const ProductGroupNameCell = styled("th")(({ theme }) => ({
  borderBottomWidth: "0 !important",
  fontWeight: 400,
  fontSize: 20,
  lineHeight: 23.44 / 20,
  color: theme.colors.kaspersky,
}));

export const ProductNameCell = styled("th")(({ theme }) => ({
  borderTopWidth: "0 !important",
  fontWeight: 400,
  fontSize: 16,
  lineHeight: 18.75 / 16,
  color: theme.colors.black,
}));

export const Row = styled("tr")<{ "data-group-type"?: string }>((props) => ({
  [`&[data-group-state=false] ~ [data-group-type='${props["data-group-type"]}']`]:
    {
      display: "none",
    },
  // "&[data-group-caption]": {
  //   "td, th": {
  //     borderRightWidth: "0 !important",
  //     borderLeftWidth: "0 !important",
  //   },
  // },
}));

export const Root = styled("table")(({ theme }) => ({
  width: "100%",
  tableLayout: "fixed",
  borderCollapse: "collapse",
  marginBottom: 50,
  "th, td": {
    borderWidth: 1,
    borderStyle: "solid",
    // borderColor: theme.colors.grey,
    borderColor: "rgba(0,0,0,.1)",
    "&:first-child": {
      borderLeftWidth: 0,
    },
    "&:last-child": {
      borderRightWidth: 0,
    },
  },
  thead: {
    zIndex: 2,
    position: "sticky",
    top: 0,
    background: "rgba(255, 255, 255, 0.9)",
    // backdropFilter: "blur(50px)",
    // backgroundImage:
    //   "linear-gradient(to bottom, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 0))",
    "th, td": {
      borderTopWidth: 0,
    },
    th: {
      padding: 10,

      "&:first-child": {
        width: 270,
      },
    },
  },
  tbody: {
    [Row]: {
      th: {
        boxSizing: "border-box",
        paddingTop: 15,
        paddingRight: 20,
        paddingBottom: 15,
        textAlign: "left",
      },
      td: {
        padding: 20,
      },
      ":last-child": {
        "th, td": {
          borderBottomWidth: 0,
        },
      },
      "&[data-group-caption]": {
        backgroundColor: "rgba(0, 168, 142, 0.02)",
      },
    },
  },
}));
