import styled from "styled-components";
import Button from "./Button";

import { ReactComponent as AskQuestionSvg } from "./AskQuestion.svg";

const Root = styled("div")({
  position: "relative",
  paddingTop: 74,
  paddingLeft: 106,
  display: "flex",
  alignItems: "end",
  marginTop: 120,
  marginBottom: 120,
});

const Decor = styled(AskQuestionSvg)({
  position: "absolute",
  left: 0,
  top: 0,
  zIndex: -1,
});

const Cell = styled("div")({
  width: "50%",
});

const CellAction = styled(Cell)({
  display: "flex",
  justifyContent: "center",
  [Button]: {
    minWidth: 440,
  },
});

const Title = styled("h3")(({ theme }) => ({
  color: theme.colors.kaspersky,
  fontWeight: 600,
  fontSize: 60,
  lineHeight: 70 / 60,
  margin: 0,
}));

const AskQuestion = () => (
  <Root>
    <Decor />
    <Cell>
      <Title>Остались вопросы? Мы поможем!</Title>
    </Cell>
    <CellAction>
      <Button
        as="a"
        href="https://www.kaspersky.ru/small-to-medium-business-security/contact-us"
        target="_blank"
        rel="noreferrer"
        data-type="primary"
      >
        Задать вопрос
      </Button>
    </CellAction>
  </Root>
);

export default AskQuestion;
