import styled from "styled-components";
import { ReactComponent as ArrowSvg } from "./arrow.svg";

type Direction = "up" | "right" | "down" | "left";
interface ArrowProps {
  direction?: Direction;
}

const rotate = (direction: Direction) => {
  switch (direction) {
    case "up":
      return -180;
    case "right":
      return -90;
    case "down":
      return 0;
    case "left":
      return 90;
  }
};

export const Arrow = styled(ArrowSvg)<ArrowProps>(({ direction = "down" }) => ({
  width: 10,
  height: 13,
  display: "inline-flex",
  transform: `rotate(${rotate(direction)}deg)`,
  transition: "transform 0.25s",
  path: {
    stroke: "currentColor",
  },
}));
