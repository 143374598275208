const theme = {
  colors: {
    kaspersky: "#00A88E",
    kasperskyTeal: "#23D1AE",
    black: "#1D1D1B",
    grey: "#BBBBBA",
    darkGrey: "#545454",
  },
  maxWidth: 1400,
};

export default theme;
