import { useCallback, useEffect, useState } from "react";

import * as data from "../../data";
import { Feature as FeatureCaption } from "./Caption/Feature";
import { Group as GroupCaption } from "./Caption/Group";
import { ModeLink } from "./ModeLink";
import { Value } from "./Value";
import { ProductGroupNameCell, ProductNameCell, Root, Row } from "./styled";

interface TableProps {
  products: data.ProductType[];
  features: data.FeatureType[];
}
export const Table = (props: TableProps) => {
  const [features, setFeatures] = useState<data.Feature[]>([]);
  const [products, setProducts] = useState<data.Product[]>([]);
  const [compareState, setCompareState] = useState<string[]>(props.products);
  const [featureState, setFeatureState] = useState<string[]>([]);
  const [editMode, setEditMode] = useState(false);

  const toggleCompare = useCallback(
    (type: string) => {
      setCompareState((prev) =>
        prev.includes(type) ? prev.filter((v) => v !== type) : [...prev, type]
      );
    },
    [setCompareState]
  );

  const toggleFeature = useCallback((type: string) => {
    setFeatureState((prev) =>
      prev.includes(type) ? prev.filter((v) => v !== type) : [...prev, type]
    );
  }, []);

  useEffect(() => {
    setProducts(
      props.products
        .filter((type) =>
          editMode ? props.products.includes(type) : compareState.includes(type)
        )
        .map((type) => data.products[type])
    );
  }, [compareState, editMode, props.products]);

  useEffect(() => {
    const filtered = props.features.filter((v) => !v.includes("_Mobile"));
    setFeatures(filtered.map((type) => data.features[type]));
    // console.log(features[0].group?.type);
    setFeatureState([data.features[filtered[0]].group?.type!]);
  }, [props.features]);

  const multipleProducts =
    Array.from(new Set(Array.from(products.map(({ group }) => group)))).length >
    1;

  return (
    <>
      <ModeLink {...{ editMode, setEditMode }} />
      <Root>
        <thead>
          {multipleProducts && (
            <tr>
              <th rowSpan={2} />
              {products.map(({ group }, ix, list) => {
                const groupIx = list.findIndex((f) => f.group === group);
                const groupCount = list.filter((f) => f.group === group).length;
                const showGroup = ix === groupIx;

                if (!showGroup) return null;

                return (
                  <ProductGroupNameCell
                    colSpan={groupCount}
                    key={`product_group_name_${ix}`}
                  >
                    {group.name}
                  </ProductGroupNameCell>
                );
              })}
            </tr>
          )}
          <tr>
            {!multipleProducts && <ProductNameCell />}
            {products.map(({ name, type }, ix) => (
              <ProductNameCell key={`product_name_${ix}`}>
                {editMode && (
                  <div>
                    <input
                      type="checkbox"
                      checked={compareState.includes(type)}
                      onClick={() => {
                        toggleCompare(type);
                      }}
                    />
                  </div>
                )}
                {name}
              </ProductNameCell>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.values(features)
            .filter((feature) =>
              products.some((p: any) => feature.type in p.features)
            )
            .map((feature, ix, list) => {
              const { group } = feature;
              const groupIx = list.findIndex((f) => f.group === group);
              const groupCount = list.filter((f) => f.group === group).length;
              const showGroup: boolean =
                group !== undefined && ix === groupIx && groupCount > 1;

              return (
                <>
                  {showGroup && group?.type && (
                    <Row
                      data-group-caption
                      data-group-type={group?.type}
                      data-group-state={featureState.includes(group.type)}
                      key={`feature_group_${ix}`}
                    >
                      <th
                        onClick={() => {
                          group?.type && toggleFeature(group?.type);
                        }}
                      >
                        <GroupCaption
                          {...group}
                          state={featureState.includes(group.type)}
                        />
                      </th>
                      {products.map((_, ix) => (
                        <td key={`feature_group_product_${ix}`} />
                      ))}
                    </Row>
                  )}
                  <Row data-group-type={group?.type} key={`feature_${ix}`}>
                    <th>
                      <FeatureCaption {...feature} />
                    </th>
                    {products.map((p: any, ix) => (
                      <td key={`feature_product_${ix}`}>
                        <Value value={p.features[feature.type]} />
                      </td>
                    ))}
                  </Row>
                </>
              );
            })}
        </tbody>
      </Root>
    </>
  );
};
