import styled from "styled-components";

interface ButtonProps {
  "data-type": "primary" | "secondary";
}
const Button = styled("button")<ButtonProps>(({ theme }) => ({
  appearance: "none",
  margin: 0,
  padding: "10px 30px",

  textDecoration: "none",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",

  borderRadius: 10,
  border: "1px solid",

  fontWeight: 600,
  fontSize: 16,
  lineHeight: 19 / 16,

  height: 50,
  boxSizing: "border-box",

  "&[data-type=primary]": {
    borderColor: theme.colors.kaspersky,
    background: theme.colors.kaspersky,
    color: "#fff",
  },

  "&[data-type=secondary]": {
    background: "#fff",
    borderColor: theme.colors.kaspersky,
    color: theme.colors.kaspersky,
  },
}));

export default Button;
