import * as icons from "../icons";
import { FeatureBase, Value } from "./features";
import { ProductGroupBase, ProductBase } from "./products";

export enum FeatureType {
  VHC_API_Integration = "VHC_API_Integration",
  VHC_FPM_Protection = "VHC_FPM_Protection",
  VHC_DetectionPreventionSystem = "VHC_DetectionPreventionSystem",
  VHC_Antivirus = "VHC_Antivirus",
  VHC_DeviceProtection = "VHC_DeviceProtection",
  VHC_DesktopProgramsControl = "VHC_DesktopProgramsControl",
  VHC_BehavioralAnalysisExploitsProtection = "VHC_BehavioralAnalysisExploitsProtection",
  VHC_PublicFoldersEncryptionProtection = "VHC_PublicFoldersEncryptionProtection",
  VHC_VulnerabilityAssessmentManagement = "VHC_VulnerabilityAssessmentManagement",
  VHC_SIEM_Connectors = "VHC_SIEM_Connectors",
  VHC_ServerProgramsControl = "VHC_ServerProgramsControl",
  VHC_FIM = "VHC_FIM",
  VHC_LogsAnalysis = "VHC_LogsAnalysis",
  VHC_VMwareNSX_IDS_IPS = "VHC_VMwareNSX_IDS_IPS",
  VHC_ContainerProtection_DevOpsIntegration = "VHC_ContainerProtection_DevOpsIntegration",
  VHC_CentralManagement = "VHC_CentralManagement",
  VHC_ConvenientDeployment = "VHC_ConvenientDeployment",
  VHC_MassThreatsEndpointsProtection = "VHC_MassThreatsEndpointsProtection",
  VHC_CleanImagesRegistry = "VHC_CleanImagesRegistry",
}

export enum ProductGroupType {
  VHC = "VHC",
}

export enum ProductType {
  VHC_Standart = "VHC_Standart",
  VHC_Enterprise = "VHC_Enterprise",
  // VHC_ContainerSecurity = "VHC_ContainerSecurity",
}

export interface Feature extends FeatureBase {
  type: FeatureType;
}

export interface ProductGroup extends ProductGroupBase {
  type: ProductGroupType.VHC;
}

export interface Product extends ProductBase {
  type: ProductType;
  features: Partial<Record<FeatureType, Value>>;
}

export const features: Record<FeatureType, Feature> = {
  [FeatureType.VHC_API_Integration]: {
    type: FeatureType.VHC_API_Integration,
    title: "Интеграция с облачными API",
    icon: icons.cloud,
  },
  [FeatureType.VHC_FPM_Protection]: {
    type: FeatureType.VHC_FPM_Protection,
    title: "Защита файлов, процессов и памяти",
    icon: icons.protection,
    description:
      "Облачная консоль доступна из любой точки мира и позволяет дистанционно управлять политиками безопасности.",
  },
  [FeatureType.VHC_DetectionPreventionSystem]: {
    type: FeatureType.VHC_DetectionPreventionSystem,
    title:
      "Системы обнаружения и предотвращения вторжений, управление сетевым экраном",
    icon: icons.monitoring,
  },
  [FeatureType.VHC_Antivirus]: {
    type: FeatureType.VHC_Antivirus,
    title: "Веб-антивирус, Почтовый антивирус, Анти-Спам, Анти-Фишинг",
    icon: icons.antivirus,
  },
  [FeatureType.VHC_DeviceProtection]: {
    type: FeatureType.VHC_DeviceProtection,
    title: "Контроль защиты устройств и веб-контроль",
    icon: icons.control,
  },
  [FeatureType.VHC_DesktopProgramsControl]: {
    type: FeatureType.VHC_DesktopProgramsControl,
    title: "Контроль программ для настольных компьютеров",
    icon: icons.pc,
  },
  [FeatureType.VHC_BehavioralAnalysisExploitsProtection]: {
    type: FeatureType.VHC_BehavioralAnalysisExploitsProtection,
    title: "Поведенческий анализ и защита от эксплойтов",
    icon: icons.file,
  },
  [FeatureType.VHC_PublicFoldersEncryptionProtection]: {
    type: FeatureType.VHC_PublicFoldersEncryptionProtection,
    title: "Защита общих папок от шифрования",
    icon: icons.folder,
  },
  [FeatureType.VHC_VulnerabilityAssessmentManagement]: {
    type: FeatureType.VHC_VulnerabilityAssessmentManagement,
    title: "Оценка уязвимостей и управление установкой исправлений",
    icon: icons.tools,
  },
  [FeatureType.VHC_SIEM_Connectors]: {
    type: FeatureType.VHC_SIEM_Connectors,
    title: "SIEM-коннекторы",
    icon: icons.connection,
  },
  [FeatureType.VHC_ServerProgramsControl]: {
    type: FeatureType.VHC_ServerProgramsControl,
    title: "Контроль программ для серверных ОС",
    icon: icons.control2,
  },
  [FeatureType.VHC_FIM]: {
    type: FeatureType.VHC_FIM,
    title: "Мониторинг целостности файлов (FIM)",
    icon: icons.agreement,
  },
  [FeatureType.VHC_LogsAnalysis]: {
    type: FeatureType.VHC_LogsAnalysis,
    title: "Анализ журналов",
    icon: icons.edr,
  },
  [FeatureType.VHC_VMwareNSX_IDS_IPS]: {
    type: FeatureType.VHC_VMwareNSX_IDS_IPS,
    title: "IDS/IPS нового поколения для VMware NSX",
    icon: icons.protection2,
  },
  [FeatureType.VHC_ContainerProtection_DevOpsIntegration]: {
    type: FeatureType.VHC_ContainerProtection_DevOpsIntegration,
    title: "Защита контейнеров и интеграция для DevOps",
    icon: icons.protection,
  },
  [FeatureType.VHC_CentralManagement]: {
    type: FeatureType.VHC_CentralManagement,
    title: "Централизованное управление",
    icon: icons.analysis,
  },
  [FeatureType.VHC_ConvenientDeployment]: {
    type: FeatureType.VHC_ConvenientDeployment,
    title: "Удобное развертывание",
    icon: icons.box,
  },
  [FeatureType.VHC_MassThreatsEndpointsProtection]: {
    type: FeatureType.VHC_MassThreatsEndpointsProtection,
    title: "Защита конечных точек от массовых угроз",
    icon: icons.spider,
  },
  [FeatureType.VHC_CleanImagesRegistry]: {
    type: FeatureType.VHC_CleanImagesRegistry,
    title: "Возможность переноса «чистых» образов в отдельный реестр",
    icon: icons.paper,
  },
};

export const group: ProductGroup = {
  type: ProductGroupType.VHC,
  name: "Security для виртуальных и облачных сред",
};

export const products: Record<ProductType, Product> = {
  [ProductType.VHC_Standart]: {
    group,
    type: ProductType.VHC_Standart,
    name: "Standart",
    features: {
      [FeatureType.VHC_API_Integration]: true,
      [FeatureType.VHC_FPM_Protection]: true,
      [FeatureType.VHC_DetectionPreventionSystem]: true,
      [FeatureType.VHC_Antivirus]: true,
      [FeatureType.VHC_DeviceProtection]: true,
      [FeatureType.VHC_DesktopProgramsControl]: true,
      [FeatureType.VHC_BehavioralAnalysisExploitsProtection]: true,
      [FeatureType.VHC_PublicFoldersEncryptionProtection]: true,
    },
  },
  [ProductType.VHC_Enterprise]: {
    group,
    type: ProductType.VHC_Enterprise,
    name: "Enterprise",
    features: {
      [FeatureType.VHC_API_Integration]: true,
      [FeatureType.VHC_FPM_Protection]: true,
      [FeatureType.VHC_DetectionPreventionSystem]: true,
      [FeatureType.VHC_Antivirus]: true,
      [FeatureType.VHC_DeviceProtection]: true,
      [FeatureType.VHC_DesktopProgramsControl]: true,
      [FeatureType.VHC_BehavioralAnalysisExploitsProtection]: true,
      [FeatureType.VHC_PublicFoldersEncryptionProtection]: true,
      [FeatureType.VHC_VulnerabilityAssessmentManagement]: true,
      [FeatureType.VHC_SIEM_Connectors]: true,
      [FeatureType.VHC_ServerProgramsControl]: true,
      [FeatureType.VHC_FIM]: true,
      [FeatureType.VHC_LogsAnalysis]: true,
      [FeatureType.VHC_VMwareNSX_IDS_IPS]: true,
      [FeatureType.VHC_ContainerProtection_DevOpsIntegration]: true,
    },
  },
  // [ProductType.VHC_ContainerSecurity]: {
  //   group,
  //   type: ProductType.VHC_ContainerSecurity,
  //   name: "Container Security",
  //   features: {
  //     [FeatureType.VHC_ContainerProtection_DevOpsIntegration]: true,
  //     [FeatureType.VHC_CentralManagement]: true,
  //     [FeatureType.VHC_ConvenientDeployment]: true,
  //     [FeatureType.VHC_MassThreatsEndpointsProtection]: true,
  //     [FeatureType.VHC_CleanImagesRegistry]: true,
  //   },
  // },
};
