import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle(({ theme }) => ({
  body: {
    margin: 0,
    color: theme.colors.black,
  },
  "a:not([class])": {
    "&:link, &:visited": {
      textDecoration: "none",
      color: theme.colors.kaspersky,
    },
  },
}));

export default GlobalStyles;
