import * as icons from "../icons";
import { FeatureBase, FeatureGroupBase, Value } from "./features";
import { ProductGroupBase, ProductBase } from "./products";
import { FeatureType as BS_FeatureType } from "./business-security";

export enum FeatureGroupType {
  ESC_Defence = "ESC_Defence",
  ESC_CM = "ESC_CM",
  ESC_RI = "ESC_RI",
}

export enum FeatureType {
  ESC_MailFileWebDefence = "ESC_MailFileWebDefence",
  ESC_Firewall = "ESC_Firewall",
  ESC_NetworkAttacksProtection = "ESC_NetworkAttacksProtection",
  ESC_EncryptorsExploitsProtection = "ESC_EncryptorsExploitsProtection",
  ESC_VulnerabilityCheck = "ESC_VulnerabilityCheck",
  ESC_CloudDiscovery = "ESC_CloudDiscovery",
  ESC_MobileProtection = "ESC_MobileProtection",
  ESC_AnomaliesAdaptiveControl = "ESC_AnomaliesAdaptiveControl",
  ESC_BadUSBAttacts = "ESC_BadUSBAttacts",
  ESC_CloudBlocking = "ESC_CloudBlocking",
  ESC_DataDiscovery = "ESC_DataDiscovery",
  ESC_Office365Protection = "ESC_Office365Protection",
  ESC_WebControl = "ESC_WebControl",
  ESC_DeviceControl = "ESC_DeviceControl",
  ESC_Encryption = "ESC_Encryption",
  ESC_PatchInstallationManagement = "ESC_PatchInstallationManagement",
  ESC_ProgramsControl = "ESC_ProgramsControl",
  ESC_RemoteDataClean = "ESC_RemoteDataClean",
  ESC_RootCauseAnalysis = "ESC_RootCauseAnalysis",
  ESC_EDR = "ESC_EDR",
  ESC_CITO = "ESC_CITO",
}

export enum ProductGroupType {
  ESC = "ESC",
}

export enum ProductType {
  ESC_Cloud = "ESC_Cloud",
  ESC_CloudPlus = "ESC_CloudPlus",
  ESC_CloudPro = "ESC_CloudPro",
}

export interface FeatureGroup extends FeatureGroupBase {
  type: FeatureGroupType;
}

export interface Feature extends FeatureBase {
  type: FeatureType;
  group: FeatureGroup;
}

export interface ProductGroup extends ProductGroupBase {
  type: ProductGroupType.ESC;
}

export interface Product extends ProductBase {
  type: ProductType;
  features: Partial<Record<FeatureType | BS_FeatureType, Value>>;
}

const featureGroups: Record<FeatureGroupType, FeatureGroup> = {
  [FeatureGroupType.ESC_Defence]: {
    type: FeatureGroupType.ESC_Defence,
    title: "Защита",
    icon: icons.protection,
  },
  [FeatureGroupType.ESC_CM]: {
    type: FeatureGroupType.ESC_CM,
    title: "Контроль и управление",
    icon: icons.edr,
  },
  [FeatureGroupType.ESC_RI]: {
    type: FeatureGroupType.ESC_RI,
    title: "Реагирование и расследование",
    icon: icons.edr,
  },
};

export const features: Record<FeatureType, Feature> = {
  [FeatureType.ESC_MailFileWebDefence]: {
    type: FeatureType.ESC_MailFileWebDefence,
    group: featureGroups.ESC_Defence,
    title: "Защита от почтовых, файловых и веб-угроз",
    icon: icons.mail,
  },
  [FeatureType.ESC_Firewall]: {
    type: FeatureType.ESC_Firewall,
    group: featureGroups.ESC_Defence,
    title: "Сетевой экран",
    description:
      "Облачная консоль доступна из любой точки мира и позволяет дистанционно управлять политиками безопасности.",
    icon: icons.control,
  },
  [FeatureType.ESC_NetworkAttacksProtection]: {
    type: FeatureType.ESC_NetworkAttacksProtection,
    group: featureGroups.ESC_Defence,
    title: "Защита от сетевых атак",
    icon: icons.protection2,
  },
  [FeatureType.ESC_EncryptorsExploitsProtection]: {
    type: FeatureType.ESC_EncryptorsExploitsProtection,
    group: featureGroups.ESC_Defence,
    title: "Защита от шифровальщиков и эксплойтов",
    icon: icons.protection,
  },
  [FeatureType.ESC_VulnerabilityCheck]: {
    type: FeatureType.ESC_VulnerabilityCheck,
    group: featureGroups.ESC_Defence,
    title: "Проверка на уязвимости",
    icon: icons.monitoring,
  },
  [FeatureType.ESC_CloudDiscovery]: {
    type: FeatureType.ESC_CloudDiscovery,
    group: featureGroups.ESC_Defence,
    title: "Cloud Discovery",
    icon: icons.cloud,
  },
  [FeatureType.ESC_MobileProtection]: {
    type: FeatureType.ESC_MobileProtection,
    group: featureGroups.ESC_Defence,
    title: "Защита мобильных устройств",
    icon: icons.phone,
  },
  [FeatureType.ESC_AnomaliesAdaptiveControl]: {
    type: FeatureType.ESC_AnomaliesAdaptiveControl,
    group: featureGroups.ESC_Defence,
    title: "Адаптивный контроль аномалий",
    icon: icons.mouse,
  },
  [FeatureType.ESC_BadUSBAttacts]: {
    type: FeatureType.ESC_BadUSBAttacts,
    group: featureGroups.ESC_Defence,
    title: "Зашита от атак BadUSB",
    icon: icons.badUsb,
  },
  [FeatureType.ESC_CloudBlocking]: {
    type: FeatureType.ESC_CloudBlocking,
    group: featureGroups.ESC_CM,
    title: "Cloud blocking",
    icon: icons.cloud,
  },
  [FeatureType.ESC_DataDiscovery]: {
    type: FeatureType.ESC_DataDiscovery,
    group: featureGroups.ESC_CM,
    title: "Data discovery",
    icon: icons.chart,
  },
  [FeatureType.ESC_Office365Protection]: {
    type: FeatureType.ESC_Office365Protection,
    group: featureGroups.ESC_CM,
    title: "Защита Microsoft Office 365",
    icon: icons.file,
  },
  [FeatureType.ESC_WebControl]: {
    type: FeatureType.ESC_WebControl,
    group: featureGroups.ESC_CM,
    title: "Веб-контроль",
    icon: icons.internet,
  },
  [FeatureType.ESC_DeviceControl]: {
    type: FeatureType.ESC_DeviceControl,
    group: featureGroups.ESC_CM,
    title: "Контроль устройств",
    icon: icons.pc,
  },
  [FeatureType.ESC_Encryption]: {
    type: FeatureType.ESC_Encryption,
    group: featureGroups.ESC_CM,
    title: "Шифрование",
    icon: icons.encryption1,
  },
  [FeatureType.ESC_PatchInstallationManagement]: {
    type: FeatureType.ESC_PatchInstallationManagement,
    group: featureGroups.ESC_CM,
    title: "Управление установкой исправлений",
    icon: icons.programm2,
  },
  [FeatureType.ESC_ProgramsControl]: {
    type: FeatureType.ESC_ProgramsControl,
    group: featureGroups.ESC_CM,
    title: "Контроль программ",
    icon: icons.programm,
  },
  [FeatureType.ESC_RemoteDataClean]: {
    type: FeatureType.ESC_RemoteDataClean,
    group: featureGroups.ESC_CM,
    title: "Удаленная очистка данных",
    icon: icons.del,
  },
  [FeatureType.ESC_RootCauseAnalysis]: {
    type: FeatureType.ESC_RootCauseAnalysis,
    group: featureGroups.ESC_RI,
    title: "Анализ первопричин",
    icon: icons.analysis,
  },
  [FeatureType.ESC_EDR]: {
    type: FeatureType.ESC_EDR,
    group: featureGroups.ESC_RI,
    title: "EDR",
    icon: icons.edr,
  },
  [FeatureType.ESC_CITO]: {
    type: FeatureType.ESC_CITO,
    group: featureGroups.ESC_RI,
    title: "Тренинг по кибербезопасности для IT-специалистов (CITO)",
    icon: icons.programm,
  },
};

export const group: ProductGroup = {
  type: ProductGroupType.ESC,
  name: "Endpoint Security Cloud",
};

export const products: Record<ProductType, Product> = {
  [ProductType.ESC_Cloud]: {
    group,
    type: ProductType.ESC_Cloud,
    name: "Cloud",
    features: {
      [FeatureType.ESC_MailFileWebDefence]: true,
      [FeatureType.ESC_Firewall]: true,
      [FeatureType.ESC_NetworkAttacksProtection]: true,
      [FeatureType.ESC_EncryptorsExploitsProtection]: true,
      [FeatureType.ESC_VulnerabilityCheck]: true,
      [FeatureType.ESC_CloudDiscovery]: true,
      [FeatureType.ESC_MobileProtection]: true,

      [BS_FeatureType.BS_SM_DeviceCount]: "От 5 до 249 узлов",
      [BS_FeatureType.BS_SM_CloudConsole]: true,
      [BS_FeatureType.BS_WS_MalwareProtection]: true,
      [BS_FeatureType.BS_WS_HIPS]: true,
      [BS_FeatureType.BS_WS_Firewall]: true,
      [BS_FeatureType.BS_WS_CryptographersProtection]: true,
      [BS_FeatureType.BS_FS_MalwareProtection]: true,
      [BS_FeatureType.BS_FS_CryptographersProtection]: true,
      [BS_FeatureType.BS_Mobile_Defence]: true,
      [BS_FeatureType.BS_Mobile_AppControl]: true,
      [BS_FeatureType.BS_Mobile_Management]: true,
      [BS_FeatureType.BS_Mobile_AntiTief]: true,
      [BS_FeatureType.BS_Platforms_Windows]: true,
      [BS_FeatureType.BS_Platforms_macOS]: true,
      [BS_FeatureType.BS_Platforms_Android]: true,
      [BS_FeatureType.BS_Platforms_iOS]: true,
      [BS_FeatureType.BS_Platforms_WindowsServer]: true,
    },
  },
  [ProductType.ESC_CloudPlus]: {
    group,
    type: ProductType.ESC_CloudPlus,
    name: "Cloud Plus",
    features: {
      [FeatureType.ESC_MailFileWebDefence]: true,
      [FeatureType.ESC_Firewall]: true,
      [FeatureType.ESC_NetworkAttacksProtection]: true,
      [FeatureType.ESC_EncryptorsExploitsProtection]: true,
      [FeatureType.ESC_VulnerabilityCheck]: true,
      [FeatureType.ESC_CloudDiscovery]: true,
      [FeatureType.ESC_MobileProtection]: true,
      [FeatureType.ESC_CloudBlocking]: true,
      [FeatureType.ESC_DataDiscovery]: true,
      [FeatureType.ESC_Office365Protection]: true,
      [FeatureType.ESC_WebControl]: true,
      [FeatureType.ESC_Encryption]: true,
      [FeatureType.ESC_PatchInstallationManagement]: true,
      [FeatureType.ESC_RootCauseAnalysis]: true,

      [BS_FeatureType.BS_SM_DeviceCount]: "От 5 до 249 узлов",
      [BS_FeatureType.BS_SM_CloudConsole]: true,
      [BS_FeatureType.BS_WS_MalwareProtection]: true,
      [BS_FeatureType.BS_WS_HIPS]: true,
      [BS_FeatureType.BS_WS_Firewall]: true,
      [BS_FeatureType.BS_WS_ControlWeb]: true,
      [BS_FeatureType.BS_WS_ControlDevice]: true,
      [BS_FeatureType.BS_WS_DataEncryptions]: true,
      [BS_FeatureType.BS_WS_CryptographersProtection]: true,
      [BS_FeatureType.BS_FS_MalwareProtection]: true,
      [BS_FeatureType.BS_FS_CryptographersProtection]: true,
      [BS_FeatureType.BS_Mobile_Defence]: true,
      [BS_FeatureType.BS_Mobile_AppControl]: true,
      [BS_FeatureType.BS_Mobile_Management]: true,
      [BS_FeatureType.BS_Mobile_AntiTief]: true,
      [BS_FeatureType.BS_Platforms_Windows]: true,
      [BS_FeatureType.BS_Platforms_macOS]: true,
      [BS_FeatureType.BS_Platforms_Android]: true,
      [BS_FeatureType.BS_Platforms_iOS]: true,
      [BS_FeatureType.BS_Platforms_WindowsServer]: true,
      [BS_FeatureType.BS_SA_PatchManagement]: true,
    },
  },
  [ProductType.ESC_CloudPro]: {
    group,
    type: ProductType.ESC_CloudPro,
    name: "Cloud Pro",
    features: {
      [FeatureType.ESC_MailFileWebDefence]: true,
      [FeatureType.ESC_Firewall]: true,
      [FeatureType.ESC_NetworkAttacksProtection]: true,
      [FeatureType.ESC_EncryptorsExploitsProtection]: true,
      [FeatureType.ESC_VulnerabilityCheck]: true,
      [FeatureType.ESC_CloudDiscovery]: true,
      [FeatureType.ESC_MobileProtection]: true,
      [FeatureType.ESC_AnomaliesAdaptiveControl]: true,
      [FeatureType.ESC_BadUSBAttacts]: true,
      [FeatureType.ESC_CloudBlocking]: true,
      [FeatureType.ESC_DataDiscovery]: true,
      [FeatureType.ESC_Office365Protection]: true,
      [FeatureType.ESC_WebControl]: true,
      [FeatureType.ESC_Encryption]: true,
      [FeatureType.ESC_PatchInstallationManagement]: true,
      [FeatureType.ESC_ProgramsControl]: true,
      [FeatureType.ESC_RemoteDataClean]: true,
      [FeatureType.ESC_RootCauseAnalysis]: true,
      [FeatureType.ESC_EDR]: true,
      [FeatureType.ESC_CITO]: true,

      [BS_FeatureType.BS_SM_DeviceCount]: "От 5 до 999 узлов",
      [BS_FeatureType.BS_SM_CloudConsole]: true,
      [BS_FeatureType.BS_WS_MalwareProtection]: true,
      [BS_FeatureType.BS_WS_HIPS]: true,
      [BS_FeatureType.BS_WS_Firewall]: true,
      [BS_FeatureType.BS_WS_ControlProgram]: true,
      [BS_FeatureType.BS_WS_ControlWeb]: true,
      [BS_FeatureType.BS_WS_ControlDevice]: true,
      [BS_FeatureType.BS_WS_ControlAnomalies]: true,
      [BS_FeatureType.BS_WS_DataEncryptions]: true,
      [BS_FeatureType.BS_WS_CryptographersProtection]: true,
      [BS_FeatureType.BS_FS_MalwareProtection]: true,
      [BS_FeatureType.BS_FS_CryptographersProtection]: true,
      [BS_FeatureType.BS_Mobile_Defence]: true,
      [BS_FeatureType.BS_Mobile_AppControl]: true,
      [BS_FeatureType.BS_Mobile_Management]: true,
      [BS_FeatureType.BS_Mobile_AntiTief]: true,
      [BS_FeatureType.BS_Platforms_Windows]: true,
      [BS_FeatureType.BS_Platforms_macOS]: true,
      [BS_FeatureType.BS_Platforms_Android]: true,
      [BS_FeatureType.BS_Platforms_iOS]: true,
      [BS_FeatureType.BS_Platforms_WindowsServer]: true,
      [BS_FeatureType.BS_SA_PatchManagement]: true,
      [BS_FeatureType.BS_EDRTools]: {
        value: true,
        text: "начальный функционал",
      },
    },
  },
};
