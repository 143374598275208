import styled from "styled-components";

const Root = styled("div")({
  textAlign: "right",
  marginBottom: 20,
});

const Link = styled("a")(({ theme }) => ({
  fontWeight: 600,
  fontSize: 16,
  lineHeight: 18.75 / 16,
  color: theme.colors.kaspersky,
  cursor: "pointer",
}));

interface ModeLinkProps {
  editMode: boolean;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModeLink = ({ editMode, setEditMode }: ModeLinkProps) => {
  return (
    <Root>
      <Link
        onClick={() => {
          setEditMode((prev) => !prev);
        }}
      >
        {editMode
          ? "Сравнить выбранные продукты"
          : "Выбрать продукты для сравнения"}
      </Link>
    </Root>
  );
};
