import styled from "styled-components";

const Root = styled("footer")({
  display: "flex",
  justifyContent: "space-between",
  position: "relative",
  padding: "30px 0",

  color: "#BBBABA",

  fontWeight: 500,
  fontSize: 10,
  lineHeight: 12 / 10,

  "::before": {
    content: "''",
    position: "absolute",
    display: "block",
    height: "100%",
    width: "100vw",
    minWidth: 1280,
    left: "50%",
    top: 0,
    transform: "translateX(-50%)",
    backgroundColor: "#F4F4F4",
    zIndex: -1,
  },
});

const Copy = styled("div")({});

const List = styled("ul")({
  listStyleType: "none",
  margin: 0,
  padding: 0,
  display: "flex",
  flexWrap: "wrap",
});
const Item = styled("li")({
  margin: "0 20px",
});
const Link = styled("a").attrs({
  target: "_blank",
  rel: "noreferrer",
})({
  color: "inherit",
  textDecoration: "none",
  ":hover": {
    textDecoration: "underline",
  },
});

const links = [
  [
    "Политика конфиденциальности",
    "https://www.kaspersky.ru/web-privacy-policy",
  ],
  [
    "Антикоррупционная политика",
    "https://www.kaspersky.ru/anti-corruption-policy",
  ],
  [
    "Лицензионное соглашение B2C",
    "https://www.kaspersky.ru/end-user-license-agreement",
  ],
  ["Лицензионное соглашение B2B", "https://www.kaspersky.ru/business/eula"],
  ["Юридическая информация", "https://www.kaspersky.ru/legal"],
  ["Контакты", "https://www.kaspersky.ru/about/contact"],
];

export const Footer = () => (
  <Root>
    <Copy>&copy; {new Date().getFullYear()} АО «Лаборатория Касперского»</Copy>
    <List>
      {links.map(([title, href], key) => (
        <Item key={key}>
          <Link href={href}>{title}</Link>
        </Item>
      ))}
    </List>
  </Root>
);
