import styled from "styled-components";

export const Caption = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: 15,
  "& > :not(:first-child, :last-child)": {
    flexGrow: 1,
  },
});
