import React from "react";
import styled from "styled-components";
import { Header } from "./Header";
import Navigation from "./Navigation";

import defaultHeaderImage from "./Header.jpg";
import AskQuestion from "./AskQuestion";
import { Footer } from "./Footer";

interface PageProps {
  children?: React.ReactNode;
  headerImage?: string;
}
const Main = styled("main")(({ theme: { maxWidth } }) => ({
  maxWidth,
  minWidth: 1280,
  boxSizing: "border-box",
  marginLeft: "auto",
  marginRight: "auto",
  paddingLeft: 24,
  paddingRight: 24,
}));

const Page = ({ headerImage = defaultHeaderImage, children }: PageProps) => (
  <Main>
    <Header image={headerImage} />
    <Navigation />

    {children}

    <AskQuestion />
    <Footer />
  </Main>
);

export default Page;
