import * as icons from "../icons";
import { FeatureBase, FeatureGroupBase, Value } from "./features";
import { ProductGroupBase, ProductBase } from "./products";

export enum FeatureGroupType {
  BS_SM = "BS_SM",
  BS_WS = "BS_WS",
  BS_FS = "BS_FS",
  BS_Mobile = "BS_Mobile",
  BS_Platforms = "BS_Platforms",
  BS_SA = "BS_SA",
}

export enum FeatureType {
  // SecurityManagement
  BS_SM_DeviceCount = "BS_SM_DeviceCount",
  BS_SM_CloudConsole = "BS_SM_CloudConsole",
  BS_SM_LocalConsole = "BS_SM_LocalConsole",
  BS_SM_SIEMEntegration = "BS_SM_SIEMEntegration",
  BS_SM_EDR = "BS_SM_EDR",

  // WorkStations
  BS_WS_MalwareProtection = "BS_WS_MalwareProtection",
  BS_WS_HIPS = "BS_WS_HIPS",
  BS_WS_Firewall = "BS_WS_Firewall",
  BS_WS_ControlProgram = "BS_WS_ControlProgram",
  BS_WS_ControlWeb = "BS_WS_ControlWeb",
  BS_WS_ControlDevice = "BS_WS_ControlDevice",
  BS_WS_ControlAnomalies = "BS_WS_ControlAnomalies",
  BS_WS_DataEncryptions = "BS_WS_DataEncryptions",
  BS_WS_CryptographersProtection = "BS_WS_CryptographersProtection",

  // FileServers
  BS_FS_MalwareProtection = "BS_FS_MalwareProtection",
  BS_FS_CryptographersProtection = "BS_FS_CryptographersProtection",
  BS_FS_ControlAppLaunch = "BS_FS_ControlAppLaunch",

  // Mobile
  BS_Mobile_Defence = "BS_Mobile_Defence",
  BS_Mobile_AppControl = "BS_Mobile_AppControl",
  BS_Mobile_Management = "BS_Mobile_Management",
  BS_Mobile_AntiTief = "BS_Mobile_AntiTief",
  BS_Mobile_EMMIntegration = "BS_Mobile_EMMIntegration",

  // Platforms
  BS_Platforms_Windows = "BS_Platforms_Windows",
  BS_Platforms_macOS = "BS_Platforms_macOS",
  BS_Platforms_Linux = "BS_Platforms_Linux",
  BS_Platforms_Android = "BS_Platforms_Android",
  BS_Platforms_iOS = "BS_Platforms_iOS",
  BS_Platforms_WindowsServer = "BS_Platforms_WindowsServer",

  // SystemAdministration
  BS_SA_Deploy = "BS_SA_Deploy",
  BS_SA_Inventory = "BS_SA_Inventory",
  BS_SA_PatchManagement = "BS_SA_PatchManagement",
  BS_SA_RemoteDebug = "BS_SA_RemoteDebug",

  BS_EDRTools = "BS_EDRTools",
  BS_MailServers = "BS_MailServers",
  BS_InternetGateways = "BS_InternetGateways",
  BS_VHC = "BS_VHC",
  BS_Sandbox = "BS_Sandbox",
  BS_ExtendSupport = "BS_ExtendSupport",
}

export enum ProductGroupType {
  BS = "BS",
}

export enum ProductType {
  BS_Select = "BS_Select",
  BS_Advanced = "BS_Advanced",
  BS_Optimum = "BS_Optimum",
  BS_Total = "BS_Total",
  BS_TotalPlus = "BS_TotalPlus",
}

export interface FeatureGroup extends FeatureGroupBase {
  type: FeatureGroupType;
}

export interface Feature extends FeatureBase {
  type: FeatureType;
  group?: FeatureGroup;
}

export interface ProductGroup extends ProductGroupBase {
  type: ProductGroupType.BS;
}

export interface Product extends ProductBase {
  type: ProductType;
  features: Partial<Record<FeatureType, Value>>;
}

const featureGroups: Record<FeatureGroupType, FeatureGroup> = {
  [FeatureGroupType.BS_SM]: {
    type: FeatureGroupType.BS_SM,
    title: "Управление безопасностью",
    icon: icons.protection,
  },
  [FeatureGroupType.BS_WS]: {
    type: FeatureGroupType.BS_WS,
    title: "Рабочие станции",
    icon: icons.pc,
  },
  [FeatureGroupType.BS_FS]: {
    type: FeatureGroupType.BS_FS,
    title: "Серверные ОС",
    icon: icons.file,
  },
  [FeatureGroupType.BS_Mobile]: {
    type: FeatureGroupType.BS_Mobile,
    title: "Мобильные устройства",
    icon: icons.phone,
  },
  [FeatureGroupType.BS_Platforms]: {
    type: FeatureGroupType.BS_Platforms,
    title: "Платформы",
    icon: icons.control,
  },
  [FeatureGroupType.BS_SA]: {
    type: FeatureGroupType.BS_SA,
    title: "Системное администрирование",
    icon: icons.setting,
  },
};

export const features: Record<FeatureType, Feature> = {
  [FeatureType.BS_SM_DeviceCount]: {
    type: FeatureType.BS_SM_DeviceCount,
    group: featureGroups.BS_SM,
    title: "Количество защищаемых устройств",
    icon: icons.pc,
  },
  [FeatureType.BS_SM_CloudConsole]: {
    type: FeatureType.BS_SM_CloudConsole,
    group: featureGroups.BS_SM,
    title: "Облачная консоль",
    description:
      "Облачная консоль доступна из любой точки мира и позволяет дистанционно управлять политиками безопасности.",
    icon: icons.cloud,
  },
  [FeatureType.BS_SM_LocalConsole]: {
    type: FeatureType.BS_SM_LocalConsole,
    group: featureGroups.BS_SM,
    title: "Локальная консоль",
    description:
      "Локальная консоль управления предоставляет максимум возможностей для гибкой настройки инструментов контроля и средств защиты.",
    icon: icons.pc,
  },
  [FeatureType.BS_SM_SIEMEntegration]: {
    type: FeatureType.BS_SM_SIEMEntegration,
    group: featureGroups.BS_SM,
    title: "Интеграция с системами SIEM",
    description:
      "Автоматизированная выгрузка данных о событиях позволяет системе SIEM практически в реальном времени сопоставлять информацию и выявлять инциденты безопасности.",
    icon: icons.control,
  },
  [FeatureType.BS_SM_EDR]: {
    type: FeatureType.BS_SM_EDR,
    group: featureGroups.BS_SM,
    title: "Единый агент с EDR",
    description:
      "Не нужно устанавливать отдельный агент; доступно простое развертывание EDR при установленном решении для защиты рабочих мест.",
    icon: icons.edr,
  },
  [FeatureType.BS_WS_MalwareProtection]: {
    type: FeatureType.BS_WS_MalwareProtection,
    group: featureGroups.BS_WS,
    title: "Защита от вредоносного ПО",
    description:
      "Многоуровневая защита от киберугроз с использованием методов машинного обучения и поведенческого анализа.",
    icon: icons.spider,
  },
  [FeatureType.BS_WS_HIPS]: {
    type: FeatureType.BS_WS_HIPS,
    group: featureGroups.BS_WS,
    title: "Система предотвращения вторжений (HIPS)",
    description:
      "На основе данных Kaspersky Security Network образуется четыре стандартные группы доверенных программ, а HIPS распределяет программы по этим группам. Самые доверенные из них заносятся в белый список и запускаются без ограничений. Остальные имеют ограниченные привилегии и лимитированный доступ к критическим сетевым ресурсам.",
    icon: icons.chess,
  },
  [FeatureType.BS_WS_Firewall]: {
    type: FeatureType.BS_WS_Firewall,
    group: featureGroups.BS_WS,
    title: "Сетевой экран",
    description:
      "Усовершенствованный сетевой экран защищает рабочие места от сетевых угроз, контролируя входящий и исходящий трафик по таким параметрам, как номера портов, IP-адреса, программы, генерирующие трафик, и др. Благодаря предварительно заданным правилам для более чем 250 наиболее распространенных программ можно значительно сэкономить время при настройке сетевого экрана.",
    icon: icons.control,
  },
  [FeatureType.BS_WS_ControlProgram]: {
    type: FeatureType.BS_WS_ControlProgram,
    group: featureGroups.BS_WS,
    title: "Контроль программ",
    description:
      "Контроль программ с динамическими белыми списками позволяет IT-администраторам разрешать, блокировать и регулировать использование программ. Одним из самых эффективных способов обеспечения безопасности является режим «Запрет по умолчанию», который запрещает использование любых программ за исключением разрешенных. Контроль привилегий позволяет ограничивать права доступа программ к выбранным файлам и ресурсам.",
    icon: icons.control2,
  },
  [FeatureType.BS_WS_ControlWeb]: {
    type: FeatureType.BS_WS_ControlWeb,
    group: featureGroups.BS_WS,
    title: "Веб-контроль",
    description:
      "Этот инструмент отслеживает, фильтрует и контролирует список веб-сайтов, разрешённых для сотрудников, что не только повышает производительность, но и защищает рабочие места от вредоносного ПО и атак.",
    icon: icons.internet,
  },
  [FeatureType.BS_WS_ControlDevice]: {
    type: FeatureType.BS_WS_ControlDevice,
    group: featureGroups.BS_WS,
    title: "Контроль устройств",
    description:
      "Контроль устройств позволяет гибко контролировать USB-порты — на уровне шины, типа и конкретного устройства (вплоть до Device ID); таким образом можно повышать безопасность системы, не снижая продуктивность пользователя.",
    icon: icons.programm,
  },
  [FeatureType.BS_WS_ControlAnomalies]: {
    type: FeatureType.BS_WS_ControlAnomalies,
    group: featureGroups.BS_WS,
    title: "Адаптивный контроль аномалий",
    description:
      "Изучает поведение пользователей и блокирует нетипичные для данного пользователя или группы пользователей действия в программах.",
    icon: icons.mouse,
  },
  [FeatureType.BS_WS_DataEncryptions]: {
    type: FeatureType.BS_WS_DataEncryptions,
    group: featureGroups.BS_WS,
    title: "Шифрование данных",
    description:
      "Шифрование данных помогает защитить ценные корпоративные данные в случае кражи или утери устройства. Вы можете использовать полное шифрование диска либо шифрование отдельных папок.\n\n Если вы предпочитаете стороннее шифрование (например, Microsoft BitLocker или FileVault), вы можете управлять им из консоли.",
    icon: icons.encryption1,
  },
  [FeatureType.BS_WS_CryptographersProtection]: {
    type: FeatureType.BS_WS_CryptographersProtection,
    group: featureGroups.BS_WS,
    title: "Защита от шифровальщиков",
    description:
      "Технологии защиты, которые базируются на моделях поведенческого анализа, позволяют эффективно обнаруживать и блокировать попытки шифрования. С помощью функции отката вредоносных действий можно отменить все действия, произведенные вредоносной программой.",
    icon: icons.encryption2,
  },
  [FeatureType.BS_FS_MalwareProtection]: {
    type: FeatureType.BS_FS_MalwareProtection,
    group: featureGroups.BS_FS,
    title: "Защита от вредоносного ПО",
    description:
      "Сочетание передовых облачных и классических антивирусных технологий позволяет обезопасить файловые серверы от известных, неизвестных и сложных угроз. Продукт поддерживает несколько платформ и при этом не влияет на производительность системы.",
    icon: icons.spider,
  },
  [FeatureType.BS_FS_CryptographersProtection]: {
    type: FeatureType.BS_FS_CryptographersProtection,
    group: featureGroups.BS_FS,
    title: "Защита от программ-шифровальщиков",
    description:
      "Приложение запрещает доступ машины, на которой замечена подозрительная активность, к сетевым файловым ресурсам. Кроме того, приложение блокирует попытки шифрования непосредственно на файловом сервере.",
    icon: icons.encryption2,
  },
  [FeatureType.BS_FS_ControlAppLaunch]: {
    type: FeatureType.BS_FS_ControlAppLaunch,
    group: featureGroups.BS_FS,
    title: "Контроль запуска приложений",
    description:
      "С помощью правил можно разрешить или запретить запуск исполняемых файлов, скриптов или пакетов MSI, а также загрузку на сервер модулей DLL. Это обеспечивает исключительную защиту файловых серверов Windows.",
    icon: icons.protection2,
  },
  [FeatureType.BS_Mobile_Defence]: {
    type: FeatureType.BS_Mobile_Defence,
    group: featureGroups.BS_Mobile,
    title: "Защита",
    description:
      "Сочетание проактивных облачных методов обнаружения и анализа с традиционными технологиями обеспечивает защиту от известных, новых и комплексных угроз. Проверки по требованию и по расписанию и автоматические обновления повышают эффективность защиты.",
    icon: icons.protection,
  },
  [FeatureType.BS_Mobile_AppControl]: {
    type: FeatureType.BS_Mobile_AppControl,
    group: featureGroups.BS_Mobile,
    title: "Контроль приложений",
    description:
      "Контроль приложений позволяет разрешить использование только приложений, одобренных администратором.\n\n Пользуясь Контролем приложений, администраторы могут получать данные об установленном ПО и устанавливать нужные приложения. Интеграция с KSN упрощает создание черных и белых списков и управление ими.",
    icon: icons.encryption2,
  },
  [FeatureType.BS_Mobile_Management]: {
    type: FeatureType.BS_Mobile_Management,
    group: featureGroups.BS_Mobile,
    title: "Управление устройствами",
    description:
      "Решение содержит средства управления мобильными устройствами (MDM), которые, в частности, позволяют работать с аутентификационными сертификатами и определять параметры доступа к корпоративным принтерам и сетям Wi-Fi.",
    icon: icons.programm,
  },
  [FeatureType.BS_Mobile_AntiTief]: {
    type: FeatureType.BS_Mobile_AntiTief,
    group: featureGroups.BS_Mobile,
    title: "Анти-Вор",
    description:
      "Средства удаленной защиты защищают корпоративную информацию, даже если устройство похищено или утеряно. Доступны средства определения местонахождения и блокирования устройства, выборочной или полной очистки, отслеживания SIM-карты, создания тайного фото и активации тревожного сигнала.",
    icon: icons.thief,
  },
  [FeatureType.BS_Mobile_EMMIntegration]: {
    type: FeatureType.BS_Mobile_EMMIntegration,
    group: featureGroups.BS_Mobile,
    title: "Интеграция с EMM-системами",
    description:
      "Можно использовать EMM-решения для развертывания и настройки Kaspersky Endpoint Security для Android, что позволяет совместить средства защиты с текущими бизнес-процессами.",
    icon: icons.EMM,
  },
  [FeatureType.BS_Platforms_Windows]: {
    type: FeatureType.BS_Platforms_Windows,
    group: featureGroups.BS_Platforms,
    title: "Windows",
    icon: icons.win,
  },
  [FeatureType.BS_Platforms_macOS]: {
    type: FeatureType.BS_Platforms_macOS,
    group: featureGroups.BS_Platforms,
    title: "macOS",
    icon: icons.mac,
  },
  [FeatureType.BS_Platforms_Linux]: {
    type: FeatureType.BS_Platforms_Linux,
    group: featureGroups.BS_Platforms,
    title: "Linux",
    icon: icons.linux,
  },
  [FeatureType.BS_Platforms_Android]: {
    type: FeatureType.BS_Platforms_Android,
    group: featureGroups.BS_Platforms,
    title: "Android",
    icon: icons.android,
  },
  [FeatureType.BS_Platforms_iOS]: {
    type: FeatureType.BS_Platforms_iOS,
    group: featureGroups.BS_Platforms,
    title: "iOS",
    icon: icons.ios,
  },
  [FeatureType.BS_Platforms_WindowsServer]: {
    type: FeatureType.BS_Platforms_WindowsServer,
    group: featureGroups.BS_Platforms,
    title: "Windows Server",
    icon: icons.win,
  },
  [FeatureType.BS_SA_Deploy]: {
    type: FeatureType.BS_SA_Deploy,
    group: featureGroups.BS_SA,
    title: "Развертывание ОС и программ",
    description:
      "Автоматизированное создание, хранение и копирование защищенных образов систем, а также поддержка развертывания операционных систем (ОС) на новых машинах и переустановки. Все образы записываются в специальное хранилище, и к ним можно в любой момент получить доступ во время развертывания. Кроме того, доступна автоматическая установка более чем для 150 популярных приложений, распознаваемых сетью Kaspersky Security Network.",
    icon: icons.programm,
  },
  [FeatureType.BS_SA_Inventory]: {
    type: FeatureType.BS_SA_Inventory,
    group: featureGroups.BS_SA,
    title: "Учет программного и аппаратного обеспечения",
    description:
      "Автоматическое обнаружение, а также отслеживание аппаратного и программного обеспечения позволяют администраторам получить полную картину корпоративной сети со всеми устройствами. Автоматизированная проверка программ позволяет быстро обнаруживать их устаревшие версии, нарушающие безопасность и требующие обновления.",
    icon: icons.monitoring,
  },
  [FeatureType.BS_SA_PatchManagement]: {
    type: FeatureType.BS_SA_PatchManagement,
    group: featureGroups.BS_SA,
    title: "Патч-менеджмент",
    description:
      "Автоматизация всего цикла оценки уязвимостей и управления установкой исправлений включает в себя: \n• выявление и приоритизацию уязвимостей \n • загрузку, тестирование и распространение исправлений и обновлений \n • мониторинг результатов и составление отчетности",
    icon: icons.protection,
  },
  [FeatureType.BS_SA_RemoteDebug]: {
    type: FeatureType.BS_SA_RemoteDebug,
    group: featureGroups.BS_SA,
    title: "Удаленное устранение неполадок",
    description:
      "Kaspersky Security Center использует протокол RDP и технологию Windows Desktop Sharing (по принципу Windows Remote Assistance), чтобы сократить время реагирования, повысить эффективность и упростить поддержку удаленных объектов. Подключение к удаленным клиентским компьютерам при помощи Network Agent позволяет администратору воспользоваться доступом ко всем данным и приложениям, установленным на клиенте, даже если TCP- и UDP-порты этого компьютера закрыты.",
    icon: icons.setting,
  },
  [FeatureType.BS_EDRTools]: {
    type: FeatureType.BS_EDRTools,
    title: "Инструменты EDR",
    icon: icons.edr,
  },
  [FeatureType.BS_MailServers]: {
    type: FeatureType.BS_MailServers,
    title: "Почтовые серверы",
    description:
      "Kaspersky Security для почтовых серверов проверяет электронные сообщения и документы на почтовых серверах на базе последних версий почтовых платформ Microsoft Exchange, а также почтовых программ Sendmail, Qmail, Postfix и Exim, работающих под управлением Linux.",
    icon: icons.mail,
  },
  [FeatureType.BS_InternetGateways]: {
    type: FeatureType.BS_InternetGateways,
    title: "Интернет-шлюзы",
    description:
      "Решение обеспечивает постоянную проактивную защиту от новых и известных интернет-угроз. Высочайший уровень обнаружения вредоносного ПО сочетается с минимальным количеством ложных срабатываний. Проверка трафика HTTP и FTP с опубликованных серверов осуществляется в режиме реального времени.",
    icon: icons.internet,
  },
  [FeatureType.BS_VHC]: {
    type: FeatureType.BS_VHC,
    title: "Виртуальные и облачные среды",
    icon: icons.cloud,
  },
  [FeatureType.BS_Sandbox]: {
    type: FeatureType.BS_Sandbox,
    title: "Передовая песочница",
    icon: icons.sand,
  },
  [FeatureType.BS_ExtendSupport]: {
    type: FeatureType.BS_ExtendSupport,
    title: "Расширенная техническая поддержка",
    icon: icons.support,
  },
};

export const group: ProductGroup = {
  type: ProductGroupType.BS,
  name: "Security для бизнеса",
};

export const products: Record<ProductType, Product> = {
  [ProductType.BS_Select]: {
    group,
    type: ProductType.BS_Select,
    name: "Стандартный",
    features: {
      [FeatureType.BS_SM_DeviceCount]: "Без ограничений",
      [FeatureType.BS_SM_CloudConsole]: { value: true, text: "от 300 узлов" },
      [FeatureType.BS_SM_LocalConsole]: true,
      [FeatureType.BS_SM_SIEMEntegration]: true,
      [FeatureType.BS_SM_EDR]: true,
      [FeatureType.BS_WS_MalwareProtection]: true,
      [FeatureType.BS_WS_HIPS]: true,
      [FeatureType.BS_WS_Firewall]: true,
      [FeatureType.BS_WS_ControlProgram]: true,
      [FeatureType.BS_WS_ControlWeb]: true,
      [FeatureType.BS_WS_ControlDevice]: true,
      [FeatureType.BS_WS_CryptographersProtection]: true,
      [FeatureType.BS_FS_MalwareProtection]: true,
      [FeatureType.BS_FS_CryptographersProtection]: true,
      [FeatureType.BS_Mobile_Defence]: true,
      [FeatureType.BS_Mobile_AppControl]: true,
      [FeatureType.BS_Mobile_Management]: true,
      [FeatureType.BS_Mobile_AntiTief]: true,
      [FeatureType.BS_Mobile_EMMIntegration]: true,
      [FeatureType.BS_Platforms_Windows]: true,
      [FeatureType.BS_Platforms_macOS]: true,
      [FeatureType.BS_Platforms_Linux]: true,
      [FeatureType.BS_Platforms_Android]: true,
      [FeatureType.BS_Platforms_iOS]: true,
      [FeatureType.BS_Platforms_WindowsServer]: true,
    },
  },
  [ProductType.BS_Advanced]: {
    group,
    type: ProductType.BS_Advanced,
    name: "Расширенный",
    features: {
      [FeatureType.BS_SM_DeviceCount]: "Без ограничений",
      [FeatureType.BS_SM_CloudConsole]: { value: true, text: "от 300 узлов" },
      [FeatureType.BS_SM_LocalConsole]: true,
      [FeatureType.BS_SM_SIEMEntegration]: true,
      [FeatureType.BS_SM_EDR]: true,
      [FeatureType.BS_WS_MalwareProtection]: true,
      [FeatureType.BS_WS_HIPS]: true,
      [FeatureType.BS_WS_Firewall]: true,
      [FeatureType.BS_WS_ControlProgram]: true,
      [FeatureType.BS_WS_ControlWeb]: true,
      [FeatureType.BS_WS_ControlDevice]: true,
      [FeatureType.BS_WS_ControlAnomalies]: true,
      [FeatureType.BS_WS_DataEncryptions]: true,
      [FeatureType.BS_WS_CryptographersProtection]: true,
      [FeatureType.BS_FS_MalwareProtection]: true,
      [FeatureType.BS_FS_CryptographersProtection]: true,
      [FeatureType.BS_FS_ControlAppLaunch]: true,
      [FeatureType.BS_Mobile_Defence]: true,
      [FeatureType.BS_Mobile_AppControl]: true,
      [FeatureType.BS_Mobile_Management]: true,
      [FeatureType.BS_Mobile_AntiTief]: true,
      [FeatureType.BS_Mobile_EMMIntegration]: true,
      [FeatureType.BS_Platforms_Windows]: true,
      [FeatureType.BS_Platforms_macOS]: true,
      [FeatureType.BS_Platforms_Linux]: true,
      [FeatureType.BS_Platforms_Android]: true,
      [FeatureType.BS_Platforms_iOS]: true,
      [FeatureType.BS_Platforms_WindowsServer]: true,
      [FeatureType.BS_SA_Deploy]: true,
      [FeatureType.BS_SA_Inventory]: true,
      [FeatureType.BS_SA_PatchManagement]: true,
      [FeatureType.BS_SA_RemoteDebug]: true,
    },
  },
  [ProductType.BS_Optimum]: {
    group,
    type: ProductType.BS_Optimum,
    name: "EDR Оптимальный",
    features: {
      [FeatureType.BS_SM_DeviceCount]: "Без ограничений",
      [FeatureType.BS_SM_CloudConsole]: { value: true, text: "от 300 узлов" },
      [FeatureType.BS_SM_LocalConsole]: true,
      [FeatureType.BS_SM_SIEMEntegration]: true,
      [FeatureType.BS_SM_EDR]: true,
      [FeatureType.BS_WS_MalwareProtection]: true,
      [FeatureType.BS_WS_HIPS]: true,
      [FeatureType.BS_WS_Firewall]: true,
      [FeatureType.BS_WS_ControlProgram]: true,
      [FeatureType.BS_WS_ControlWeb]: true,
      [FeatureType.BS_WS_ControlDevice]: true,
      [FeatureType.BS_WS_ControlAnomalies]: true,
      [FeatureType.BS_WS_DataEncryptions]: true,
      [FeatureType.BS_WS_CryptographersProtection]: true,
      [FeatureType.BS_FS_MalwareProtection]: true,
      [FeatureType.BS_FS_CryptographersProtection]: true,
      [FeatureType.BS_FS_ControlAppLaunch]: true,
      [FeatureType.BS_Mobile_Defence]: true,
      [FeatureType.BS_Mobile_AppControl]: true,
      [FeatureType.BS_Mobile_Management]: true,
      [FeatureType.BS_Mobile_AntiTief]: true,
      [FeatureType.BS_Mobile_EMMIntegration]: true,
      [FeatureType.BS_Platforms_Windows]: true,
      [FeatureType.BS_Platforms_macOS]: true,
      [FeatureType.BS_Platforms_Linux]: true,
      [FeatureType.BS_Platforms_Android]: true,
      [FeatureType.BS_Platforms_iOS]: true,
      [FeatureType.BS_Platforms_WindowsServer]: true,
      [FeatureType.BS_SA_Deploy]: true,
      [FeatureType.BS_SA_Inventory]: true,
      [FeatureType.BS_SA_PatchManagement]: true,
      [FeatureType.BS_SA_RemoteDebug]: true,
      [FeatureType.BS_EDRTools]: { value: true, text: "базовый функционал" },
    },
  },
  [ProductType.BS_Total]: {
    group,
    type: ProductType.BS_Total,
    name: "Total",
    features: {
      [FeatureType.BS_SM_DeviceCount]: "Без ограничений",
      [FeatureType.BS_SM_CloudConsole]: { value: true, text: "от 300 узлов" },
      [FeatureType.BS_SM_LocalConsole]: true,
      [FeatureType.BS_SM_SIEMEntegration]: true,
      [FeatureType.BS_SM_EDR]: true,
      [FeatureType.BS_WS_MalwareProtection]: true,
      [FeatureType.BS_WS_HIPS]: true,
      [FeatureType.BS_WS_Firewall]: true,
      [FeatureType.BS_WS_ControlProgram]: true,
      [FeatureType.BS_WS_ControlWeb]: true,
      [FeatureType.BS_WS_ControlDevice]: true,
      [FeatureType.BS_WS_ControlAnomalies]: true,
      [FeatureType.BS_WS_DataEncryptions]: true,
      [FeatureType.BS_WS_CryptographersProtection]: true,
      [FeatureType.BS_FS_MalwareProtection]: true,
      [FeatureType.BS_FS_CryptographersProtection]: true,
      [FeatureType.BS_FS_ControlAppLaunch]: true,
      [FeatureType.BS_Mobile_Defence]: true,
      [FeatureType.BS_Mobile_AppControl]: true,
      [FeatureType.BS_Mobile_Management]: true,
      [FeatureType.BS_Mobile_AntiTief]: true,
      [FeatureType.BS_Mobile_EMMIntegration]: true,
      [FeatureType.BS_Platforms_Windows]: true,
      [FeatureType.BS_Platforms_macOS]: true,
      [FeatureType.BS_Platforms_Linux]: true,
      [FeatureType.BS_Platforms_Android]: true,
      [FeatureType.BS_Platforms_iOS]: true,
      [FeatureType.BS_Platforms_WindowsServer]: true,
      [FeatureType.BS_SA_Deploy]: true,
      [FeatureType.BS_SA_Inventory]: true,
      [FeatureType.BS_SA_PatchManagement]: true,
      [FeatureType.BS_SA_RemoteDebug]: true,
      [FeatureType.BS_MailServers]: true,
      [FeatureType.BS_InternetGateways]: true,
    },
  },
  [ProductType.BS_TotalPlus]: {
    group,
    type: ProductType.BS_TotalPlus,
    name: "Total Plus",
    features: {
      [FeatureType.BS_SM_DeviceCount]: "Без ограничений",
      [FeatureType.BS_SM_CloudConsole]: true,
      [FeatureType.BS_SM_LocalConsole]: true,
      [FeatureType.BS_SM_SIEMEntegration]: true,
      [FeatureType.BS_SM_EDR]: true,
      [FeatureType.BS_WS_MalwareProtection]: true,
      [FeatureType.BS_WS_HIPS]: true,
      [FeatureType.BS_WS_Firewall]: true,
      [FeatureType.BS_WS_ControlProgram]: true,
      [FeatureType.BS_WS_ControlWeb]: true,
      [FeatureType.BS_WS_ControlDevice]: true,
      [FeatureType.BS_WS_ControlAnomalies]: true,
      [FeatureType.BS_WS_DataEncryptions]: true,
      [FeatureType.BS_WS_CryptographersProtection]: true,
      [FeatureType.BS_FS_MalwareProtection]: true,
      [FeatureType.BS_FS_CryptographersProtection]: true,
      [FeatureType.BS_FS_ControlAppLaunch]: true,
      [FeatureType.BS_Mobile_Defence]: true,
      [FeatureType.BS_Mobile_AppControl]: true,
      [FeatureType.BS_Mobile_Management]: true,
      [FeatureType.BS_Mobile_AntiTief]: true,
      [FeatureType.BS_Mobile_EMMIntegration]: true,
      [FeatureType.BS_Platforms_Windows]: true,
      [FeatureType.BS_Platforms_macOS]: true,
      [FeatureType.BS_Platforms_Linux]: true,
      [FeatureType.BS_Platforms_Android]: true,
      [FeatureType.BS_Platforms_iOS]: true,
      [FeatureType.BS_Platforms_WindowsServer]: true,
      [FeatureType.BS_SA_Deploy]: true,
      [FeatureType.BS_SA_Inventory]: true,
      [FeatureType.BS_SA_PatchManagement]: true,
      [FeatureType.BS_SA_RemoteDebug]: true,
      [FeatureType.BS_EDRTools]: { value: true, text: "базовый функционал" },
      [FeatureType.BS_MailServers]: true,
      [FeatureType.BS_InternetGateways]: true,
      [FeatureType.BS_Sandbox]: true,
      [FeatureType.BS_ExtendSupport]: true,
    },
  },
};
