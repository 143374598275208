import * as icons from "../icons";
import { FeatureBase, Value } from "./features";
import { ProductGroupBase, ProductBase } from "./products";
import { FeatureType as BS_FeatureType } from "./business-security";

export enum FeatureType {
  Symphony_MassThreatsProtection = "Symphony_MassThreatsProtection",
  Symphony_MobileProtection = "Symphony_MobileProtection",
  Symphony_PatchManagement = "Symphony_PatchManagement",
  Symphony_ProgramDeviceTools = "Symphony_ProgramDeviceTools",
  Symphony_EDR = "Symphony_EDR",
  Symphony_MailServerProtection = "Symphony_MailServerProtection",
  Symphony_NetworkTrafficAnalysis = "Symphony_NetworkTrafficAnalysis",
  Symphony_MDR = "Symphony_MDR",
  Symphony_CentralMonitoring = "Symphony_CentralMonitoring",
  Symphony_GosSOPKA = "Symphony_GosSOPKA",
  Symphony_ThreatAnalysisManagement = "Symphony_ThreatAnalysisManagement",
  Symphony_EmployeeCyberLiteracy = "Symphony_EmployeeCyberLiteracy",
  Symphony_XDR = "Symphony_XDR",
}

export enum ProductGroupType {
  Symphony = "Symphony",
}

export enum ProductType {
  SymphonySecurity = "SymphonySecurity",
  SymphonyMDR = "SymphonyMDR",
  SymphonyEDR = "SymphonyEDR",
  SymphonyXDR = "SymphonyXDR",
}

export interface Feature extends FeatureBase {
  type: FeatureType;
}

export interface ProductGroup extends ProductGroupBase {
  type: ProductGroupType.Symphony;
}

export interface Product extends ProductBase {
  type: ProductType;
  features: Partial<Record<FeatureType | BS_FeatureType, Value>>;
}

export const features: Record<FeatureType, Feature> = {
  [FeatureType.Symphony_MassThreatsProtection]: {
    type: FeatureType.Symphony_MassThreatsProtection,
    title: "Защита конечных точек от массовых угроз",
    icon: icons.pc,
    description:
      "Это защита процессов, бизнес-данных и конфиденциальных сведений, хранящихся или передаваемых через устройства, подключенные к вашей сети",
  },
  [FeatureType.Symphony_MobileProtection]: {
    type: FeatureType.Symphony_MobileProtection,
    title: "Защита мобильных устройств",
    icon: icons.phone,
  },
  [FeatureType.Symphony_PatchManagement]: {
    type: FeatureType.Symphony_PatchManagement,
    title: "Патч-менеджмент",
    icon: icons.setting,
  },
  [FeatureType.Symphony_ProgramDeviceTools]: {
    type: FeatureType.Symphony_ProgramDeviceTools,
    title: "Инструменты контроля программ, устройств и интернета",
    icon: icons.control,
  },
  [FeatureType.Symphony_EDR]: {
    type: FeatureType.Symphony_EDR,
    title: "Передовое обнаружение и реагирование (EDR)",
    icon: icons.edr,
  },
  [FeatureType.Symphony_MailServerProtection]: {
    type: FeatureType.Symphony_MailServerProtection,
    title: "Защита почтовых серверов",
    icon: icons.mail,
  },
  [FeatureType.Symphony_NetworkTrafficAnalysis]: {
    type: FeatureType.Symphony_NetworkTrafficAnalysis,
    title: "Анализ сетевого трафика",
    icon: icons.analysis,
  },
  [FeatureType.Symphony_MDR]: {
    type: FeatureType.Symphony_MDR,
    title: "Управляемое обнаружение угроз и реагирование на них (MDR)",
    icon: icons.protection,
  },
  [FeatureType.Symphony_CentralMonitoring]: {
    type: FeatureType.Symphony_CentralMonitoring,
    title: "Централизованный мониторинг и корреляция событий безопасности",
    icon: icons.monitoring,
  },
  [FeatureType.Symphony_GosSOPKA]: {
    type: FeatureType.Symphony_GosSOPKA,
    title: "Модуль подключения к ГосСОПКА",
    icon: icons.gossopka,
  },
  [FeatureType.Symphony_ThreatAnalysisManagement]: {
    type: FeatureType.Symphony_ThreatAnalysisManagement,
    title: "Управление аналитическими данными об угрозах",
    icon: icons.management,
  },
  [FeatureType.Symphony_EmployeeCyberLiteracy]: {
    type: FeatureType.Symphony_EmployeeCyberLiteracy,
    title: "Платформа для повышения киберграмотности сотрудников",
    icon: icons.education,
  },
  [FeatureType.Symphony_XDR]: {
    type: FeatureType.Symphony_XDR,
    title: "Комплексная XDR-платформа",
    icon: icons.xdr,
  },
};
export const group: ProductGroup = {
  type: ProductGroupType.Symphony,
  name: "Symphony",
};

export const products: Record<ProductType, Product> = {
  [ProductType.SymphonySecurity]: {
    group,
    type: ProductType.SymphonySecurity,
    name: "Security",
    features: {
      [FeatureType.Symphony_MassThreatsProtection]: true,
      [FeatureType.Symphony_MobileProtection]: true,
      [FeatureType.Symphony_PatchManagement]: true,
      [FeatureType.Symphony_ProgramDeviceTools]: true,

      [BS_FeatureType.BS_SM_DeviceCount]: "Без ограничений",
      [BS_FeatureType.BS_SM_CloudConsole]: {
        value: true,
        text: "от 300 узлов",
      },
      [BS_FeatureType.BS_SM_LocalConsole]: true,
      [BS_FeatureType.BS_SM_SIEMEntegration]: true,
      [BS_FeatureType.BS_SM_EDR]: true,
      [BS_FeatureType.BS_WS_MalwareProtection]: true,
      [BS_FeatureType.BS_WS_HIPS]: true,
      [BS_FeatureType.BS_WS_Firewall]: true,
      [BS_FeatureType.BS_WS_ControlProgram]: true,
      [BS_FeatureType.BS_WS_ControlWeb]: true,
      [BS_FeatureType.BS_WS_ControlDevice]: true,
      [BS_FeatureType.BS_WS_ControlAnomalies]: true,
      [BS_FeatureType.BS_WS_DataEncryptions]: true,
      [BS_FeatureType.BS_WS_CryptographersProtection]: true,
      [BS_FeatureType.BS_FS_MalwareProtection]: true,
      [BS_FeatureType.BS_FS_CryptographersProtection]: true,
      [BS_FeatureType.BS_FS_ControlAppLaunch]: true,
      [BS_FeatureType.BS_Mobile_Defence]: true,
      [BS_FeatureType.BS_Mobile_AppControl]: true,
      [BS_FeatureType.BS_Mobile_Management]: true,
      [BS_FeatureType.BS_Mobile_AntiTief]: true,
      [BS_FeatureType.BS_Mobile_EMMIntegration]: true,
      [BS_FeatureType.BS_Platforms_Windows]: true,
      [BS_FeatureType.BS_Platforms_macOS]: true,
      [BS_FeatureType.BS_Platforms_Linux]: true,
      [BS_FeatureType.BS_Platforms_Android]: true,
      [BS_FeatureType.BS_Platforms_iOS]: true,
      [BS_FeatureType.BS_Platforms_WindowsServer]: true,
      [BS_FeatureType.BS_SA_Deploy]: true,
      [BS_FeatureType.BS_SA_Inventory]: true,
      [BS_FeatureType.BS_SA_PatchManagement]: true,
      [BS_FeatureType.BS_SA_RemoteDebug]: true,
      [BS_FeatureType.BS_VHC]: true,
    },
  },
  [ProductType.SymphonyMDR]: {
    group,
    type: ProductType.SymphonyMDR,
    name: "MDR",
    features: {
      [FeatureType.Symphony_MassThreatsProtection]: true,
      [FeatureType.Symphony_MobileProtection]: true,
      [FeatureType.Symphony_PatchManagement]: true,
      [FeatureType.Symphony_ProgramDeviceTools]: true,
      [FeatureType.Symphony_EDR]: {
        value: true,
        text: "Базовая функциональность",
      },
      [FeatureType.Symphony_MDR]: true,
    },
  },
  [ProductType.SymphonyEDR]: {
    group,
    type: ProductType.SymphonyEDR,
    name: "EDR",
    features: {
      [FeatureType.Symphony_MassThreatsProtection]: true,
      [FeatureType.Symphony_MobileProtection]: true,
      [FeatureType.Symphony_PatchManagement]: true,
      [FeatureType.Symphony_ProgramDeviceTools]: true,
      [FeatureType.Symphony_EDR]: {
        value: true,
        text: "Полная функциональность",
      },
    },
  },
  [ProductType.SymphonyXDR]: {
    group,
    type: ProductType.SymphonyXDR,
    name: "XDR",
    features: {
      [FeatureType.Symphony_MassThreatsProtection]: true,
      [FeatureType.Symphony_MobileProtection]: true,
      [FeatureType.Symphony_PatchManagement]: true,
      [FeatureType.Symphony_ProgramDeviceTools]: true,
      [FeatureType.Symphony_EDR]: {
        value: true,
        text: "Полная функциональность",
      },
      [FeatureType.Symphony_MailServerProtection]: true,
      [FeatureType.Symphony_NetworkTrafficAnalysis]: true,
      [FeatureType.Symphony_CentralMonitoring]: true,
      [FeatureType.Symphony_GosSOPKA]: true,
      [FeatureType.Symphony_ThreatAnalysisManagement]: true,
      [FeatureType.Symphony_EmployeeCyberLiteracy]: true,
      [FeatureType.Symphony_XDR]: true,
    },
  },
};
