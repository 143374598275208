import * as BS from "./business-security";
import * as ESC from "./esc";
import * as Symphony from "./symphony";
import * as VHC from "./vhc";

export type FeatureType =
  | BS.FeatureType
  | ESC.FeatureType
  | Symphony.FeatureType
  | VHC.FeatureType;

export type Feature = ESC.Feature | Symphony.Feature | VHC.Feature | BS.Feature;

export const features = {
  ...BS.features,
  ...ESC.features,
  ...Symphony.features,
  ...VHC.features,
};

export type ProductType =
  | BS.ProductType
  | ESC.ProductType
  | Symphony.ProductType
  | VHC.ProductType;

export type Product = BS.Product | ESC.Product | Symphony.Product | VHC.Product;

export const products = {
  ...BS.products,
  ...ESC.products,
  ...Symphony.products,
  ...VHC.products,
};
