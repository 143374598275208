import { useCallback, useState } from "react";
import styled from "styled-components";
import { FeatureBase } from "../../../data/features";
import { Arrow } from "./Arrow";
import { Caption } from "./Caption";

const Description = styled("p")(({ theme }) => ({
  color: theme.colors.darkGrey,
  margin: 0,
  fontWeight: 400,
  fontSize: 12,
  lineHeight: 14.06 / 12,

  "&[data-state=false]": {
    display: "none",
  },
}));

const Toggle = styled("div")({
  display: "flex",
  alignItems: "center",
  minHeight: 35,
  svg: {
    display: "block",
    color: "currentcolor",
  },
});

const Content = styled("div")({
  flexGrow: 1,
  textAlign: "left",
  minHeight: 35,
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
});

const Title = styled("h3")(({ theme }) => ({
  color: theme.colors.black,
  fontWeight: 400,
  fontSize: 16,
  lineHeight: 18.75 / 16,
  margin: 0,
}));

const Icon = styled("div")({
  minHeight: 35,
});

const Root = styled(Caption)({
  position: "relative",
  alignItems: "flex-start",

  "&[data-with-description='true']": {
    [Title]: {
      cursor: "pointer",
    },
    [Toggle]: {
      cursor: "pointer",
    },
  },
});

export interface FeatureProps extends FeatureBase {}

export const Feature = ({
  title,
  description,
  icon: IconSvg,
}: FeatureProps) => {
  const withDescription = Boolean(description);

  const [state, setState] = useState(false);

  const onClickCallback = useCallback(() => setState((v) => !v), []);

  return (
    <Root data-with-description={withDescription}>
      {IconSvg && (
        <Icon>
          <IconSvg />
        </Icon>
      )}
      <Content>
        <Title onClick={onClickCallback}>{title}</Title>
        {withDescription && (
          <Description data-state={state}>{description}</Description>
        )}
      </Content>
      {withDescription && (
        <Toggle onClick={onClickCallback}>
          <Arrow direction={state ? "up" : "down"} />
        </Toggle>
      )}
    </Root>
  );
};
